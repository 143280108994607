.addNewSolve {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .Up {
    display: flex;
    width: 100%;
    height: 60vh;
  }
  .addNewSolveContiner {
    padding: 10px;
    flex: 6;
  }
  .map {
    padding: 3px;
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400vh;

    // background-color: antiquewhite;
  }
}
