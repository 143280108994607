.sidebar-item {
  padding: 0.7em 1em;
  width: 100%;
  display: block;
  transition: background-color 0.2s;
  border-radius: 5px;
  align-items: center;

  .sidebar-item:hover {
    background-color: rgb(203, 39, 52);
    color: white;
    font-weight: bold;
  }
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;

    span i {
      display: inline-block;
      width: 1.5em;
    }
  }
  .sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform 0.3s;
  }

  .sidebar-content {
    padding: 0.2em;
    height: 0;
    overflow: hidden;
  }
}

.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-title.plain {
  color: #1497ee;
  text-decoration: none;
  &:hover {
    color: white;
    font-weight: bold;
  }
}
