* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

body {
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .appHeader {
    position: sticky;
    top: 0;
  }
  .appContanier {
    align-items: top;
    background-color: white;
    height: 99vh;
  }
  .appFooter {
    margin-top: auto;
  }
}

/* 
html,
body {
  height: 100vh;
}

.app {
  background-color: rgb(247, 241, 241);
  color: #000000;
}

.main {
  display: flex;
}
.contanier {
  height: 100vh;
} */
