.header {
  height: 50px;
  display: flex;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  justify-content: space-between;
  background-color: white;
  align-items: center;
  font-size: 14px;

  .headerLeft {
    display: flex;

    .logo {
      font-size: 25px;
      font-weight: bold;
      text-decoration: none;
      color: #1497ee;
      margin-left: 15px;
    }
  }
  .headerRight {
    display: flex;

    .items {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      align-items: center;

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .item {
        display: flex;
        align-items: center;
        margin-right: 15px;
        color: #464a4c;

        .link {
          text-decoration: none;
          color: #464a4c;
        }
        .icon {
          font-size: 20px;
          color: #ef476f;
        }
        p {
          margin-left: 4px;
        }

        .link Link.active {
          background-color: #555;
        }

        .link Link.hover {
          background-color: #777;
        }
      }
    }
  }
}
