* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.dashboard {
  display: flex;

  .contanier {
    display: flex;
    /* padding: 20px; */

    .left {
    }
  }
}
