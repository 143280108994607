.sidebar {
  width: 260px;
  flex-shrink: 0;
  background-color: white;
  // height: 100%;
  height: 100vh;
  overflow: auto;

  .sidebarItems {
    display: flex;

    flex-direction: column;
    align-items: center;
    color: #1497ee;
  }
}
